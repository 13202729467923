import React, { useState, useContext } from 'react';
import '../css/rankings.css';
import Rankings from '../Rankings.jsx';

const Season4 = () => {

    

    return (
        
            <div className="rankingBox">
                <Rankings/>
                <iframe src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQ4wC-4JPImZFdUxvJMFXh9Binq-sMkDi3HGtlurVZwuSuKU6W4nMvZW1Jzg3ZRaZ8f2VB1_vV7Aqzf/pubhtml?gid=217181272&single=true"></iframe>
            </div>
        
    );
};

export default Season4;