import React, { useState, useEffect } from 'react';
import mc from '../img/package art widescreen.PNG';
import board from '../img/daft punk board.png';
import packageart from '../img/package art2.png';
import './css/slider.css';

const Slideshow = () => {
    const images = [mc, mc, mc]; // Replace with your different images
    const [index, setIndex] = useState(0);
  
    useEffect(() => {
      const timer = setInterval(() => {
        setIndex((prevIndex) => 
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
      }, 2500); // Change image every 2.5 seconds
      return () => clearInterval(timer);
    }, [images.length]);
  
    return (
      <div className="slideshow">
        <img className='content' src={images[index]} alt="Slide" />
      </div>
    );
  }
  
  export default Slideshow;



