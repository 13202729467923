import React from 'react';

const Lobby = () => {
    // Your component logic goes here

    return (
        <div>
            {/* Your JSX code goes here */}
        </div>
    );
};

export default Lobby;