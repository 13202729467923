import React, {useState, useEffect} from 'react';
import $ from 'jquery';
import shield from '../img/shield.png';
import noshield from '../img/noshield.png';
import './css/play.css';
import './css/board.css';
import './css/effects.css';


const Play = () => {
    
    const rng = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }


//Visual Functions
   function removeSelectedClass() {
        // Adding a small delay to ensure asynchronous behavior
        $('.selected').removeClass('selected');
    }
    function removeYouTurnClass() {
        $('.your-turn').removeClass('your-turn');
    }

const removeShieldVisual = () => {
    console.log('removing shield visual');
    $('.shield-false').removeClass('hidden');
    $('.shield-true').addClass('hidden');
    $('.blue').removeClass('blue');
}
const addShieldVisual = () => {
    console.log('adding shield visual');
    $('.shield-true').removeClass('hidden');
    $('.shield-false').addClass('hidden');

}

useEffect(() => {
    $('.illusion').hover(
        function () {
            $(this).addClass('glow');
        },
        function () {
            $(this).removeClass('glow');
        }
    );
    $('.restoration').hover(
        function () {
            $(this).addClass('glow');
        }
        ,
        function () {
            $(this).removeClass('glow');
        }
    );
    $('.death').hover(

        function () {
            $(this).addClass('glow');
        }
        ,
        function () {
            $(this).removeClass('glow');
        }
    );
    $('.destruction').hover(
        function () {
            $(this).addClass('glow');
        }
        ,
        function () {
            $(this).removeClass('glow');
        }
    );
    $('.gatekeeper').hover(
        function () {
            $(this).addClass('glow');
        }
        ,
        function () {
            $(this).removeClass('glow');
        }
    );
    

}, []);


//State Functions
let [stats, setStats] = useState({
    wins: 0,
    losses: 0
});

let [HP, setHP] = useState({
    player: 10,
    player2: 10,
    player3: 10,
    player4: 10,
});
const update = () => {
    setHP({
        player: game.player.HP,
        player2: game.player2.HP,
        player3: game.player3.HP,
        player4: game.player4.HP
    });
}

let game = {
    game: 'active',
    turnStep: 'gateAttack',
    activePlayer: 'player',
    action: 'none',
    target: 'none',
    targetOpponent: 'none',
    death:{
        alive: true,
        HP: 6,
        Shield: 0
    },
    destruction:{
        alive: true,
        HP: 6,
        Shield: 0
    },
    illusion:{
        alive: true,
        HP: 6,
        Shield: 0
    },
    restoration:{
        alive: true,
        HP: 6,
        Shield: 0
    },
    gatekeeper: {
        alive: true,
        HP: 10,
        Shield: 0
    },
    player: {
        alive: true,
        HP: 10,
        Shield: 0,
        user: true
    }, 
    player2: {
        alive: true,
        HP: 10,
        Shield: 0,
        user: false
    },
    player3: {
        alive: true,
        HP: 10,
        Shield: 0,
        user: false
    },
    player4: {
        alive: true,
        HP: 10,
        Shield: 0,
        user: false
    },
    dice:{
        rolling: false,
        result: 0
    },
    chat: `WARNING, THIS GAME DOES NOT WORK YET`
};

let reset = () => {
    game = {
        game: 'active',
        turnStep: 'gateAttack',
        activePlayer: 'player',
        action: 'none',
        target: 'none',
        targetOpponent: 'none',
        death:{
            alive: true,
            HP: 6,
            Shield: 0
        },
        destruction:{
            alive: true,
            HP: 6,
            Shield: 0
        },
        illusion:{
            alive: true,
            HP: 6,
            Shield: 0
        },
        restoration:{
            alive: true,
            HP: 6,
            Shield: 0
        },
        gatekeeper: {
            alive: true,
            HP: 10,
            Shield: 0
        },
        player: {
            alive: true,
            HP: 10,
            Shield: 0,
            user: true
        }, 
        player2: {
            alive: true,
            HP: 10,
            Shield: 0,
            user: false
        },
        player3: {
            alive: true,
            HP: 10,
            Shield: 0,
            user: false
        },
        player4: {
            alive: true,
            HP: 10,
            Shield: 0,
            user: false
        },
        dice:{
            rolling: false,
            result: 0
        },
        chat: `WARNING, THIS GAME DOES NOT WORK YET`
    };
    $('.destruction').html(6);
    $('.illusion').html(6);
    $('.restoration').html(6);
    $('.death').html(6);
    $('.gatekeeper').html(10);
    $('.playerHP').html(10);
    $('.player2HP').html(10);
    $('.player3HP').html(10);
    $('.player4HP').html(10);
    $('.dice').html(0);
    removeSelectedClass();
    removeYouTurnClass();
    healthBar();
    $('.endscreen').css('display', 'none');
    chat('Attack a Gate');
    console.log('resetting board');
    console.log(game);
};


//Chat Funciton
const chat = (message) => {
    game.chat = message;
     // Adding a small delay to ensure asynchronous behavior
    $('.chat').html(game.chat);
}


// Turn Functions
const transitionTurnStep = () => {
    game.action = 'none';
    if (game.turnStep === 'traitRoll') {
        game.turnStep = 'gateAttack';
    } else if (game.turnStep === 'gateAttack') {
        if(game.destruction.HP <= 0 || game.illusion.HP <= 0 || game.death.HP <= 0 || game.restoration.HP <= 0){
        game.turnStep = 'Abilities';
        }else{
        endTurn();
        }
    } else if (game.turnStep === 'Abilities') {
        game.turnStep = 'gateAttack';
        game.target = 'none';
        removeSelectedClass();
        endTurn();
    }
};
const action = () => {
    stopDiceRollAnimation();
    if (game.activePlayer === 'player') {
        if(game.turnStep === 'traitRoll'){
            traitRoll();
        }else if (game.turnStep === 'gateAttack') {
            gateAttack();
        }else if (game.turnStep === 'Abilities') {
            abilities();
        }
    }else{
        chat('Not Your Turn');
    
    }
}
const endTurn = () => {
    console.log('End Turn');
    if(game.game === 'over'){
        chat('Game Over');
    }else{
        //removeYouTurnClass()

    
    game.target = 'none';
    game.targetOpponent = 'none';
    removeSelectedClass();
    if (game.activePlayer === 'player' ) {
        
        game.activePlayer = 'player2';
        cpu('player2');
        
        
    } else if (game.activePlayer === 'player2') {
        
            game.activePlayer = 'player3';
            cpu('player3');
        
    } else if (game.activePlayer === 'player3') {
        
            game.activePlayer = 'player4';
            cpu('player4');
        
        
    } else if (game.activePlayer === 'player4') {
        $(`.your-turn`).removeClass('your-turn');
        game.activePlayer = 'player';
        game.turnStep = 'gateAttack';
        chat('Your Turn');
    }
    console.log(game); 
    }
   
}
const traitRoll = () => {
    console.log('Trait Roll');
    
    transitionTurnStep();
}
const gateAttack = () => {
    if(game.target === 'none'){
        chat('No Target Selected');
        return;
    }else{
       damage(game.dice.result);
        transitionTurnStep();
    }
    
}
const abilities = () => {
        game[game.target].HP = 6;
        $(`.${game.target}`).html(game[game.target].HP);
    switch (game.target) {
        case 'destruction':
            // Code for ability 1
            
            destructionAbility();
            break;
        case 'illusion':
            // Code for ability 2
            illusionAbility();
            break;
        case 'death':
            // Code for ability 3
            deathAbility();
            break;
        case 'restoration':
            // Code for ability 4
            restorationAbility();
            break;
        default:
            console.log('Invalid action');
    }
}


//Gate Abilities
const destructionAbility = () => {
    console.log('destruction ability');
    if(game.dice.result === 1 || game.dice.result === 3 || game.dice.result === 5){
        game.target = 'gatekeeper';
        damage(game.dice.result * 2)
    }else{
        game.targetOpponent = 'player';
        directDamage(game.dice.result * 2);
    }
    console.log(game)
    transitionTurnStep();
}
const illusionAbility = () => {
    console.log('illusion ability');
    if(game.dice.result === 1 || game.dice.result === 3 || game.dice.result === 5){
        applyShield('player');
        addShieldVisual();
        chat('You get a Shield!')
    }else{
        chat('No Shield for you!')
    }
    console.log(game)
    transitionTurnStep();
}
const deathAbility = () => {
    console.log('death ability');
    if(game.dice.result === 1 || game.dice.result === 3 || game.dice.result === 5){
        if(game.targetOpponent === 'none'){
            game.targetOpponent = 'gatekeeper';
            directDamage(4);
        }else{
            directDamage(4);
        }
        
    }else{
        game.targetOpponent = 'player';
        directDamage(4);
    }
    transitionTurnStep();
}
const restorationAbility = () => {
    console.log('restoration ability');
    if(game.dice.result === 1 || game.dice.result === 3 || game.dice.result === 5){
        game.targetOpponent = game.activePlayer;
        console.log(game.target);
        directHeal(3);
    }else{
        game.targetOpponent = 'gatekeeper';
        console.log(game.target);
        directHeal(3);
    }
    console.log(game)
    transitionTurnStep();
}


// Ability Functions

const damage = (amount) => {
    if(game.target == 'none'){
        chat('No Target Selected');
        return;
    }else{
        if(game[game.target].Shield === true){
            removeShield();
            chat(`${game.target}'s shield blocked all damage`);
        }else{
            chat(`Dealing ${amount} damage to ${game.target}`);
            game[game.target].HP -= amount;
            $(`.${game.target}`).html(game[game.target].HP);
        }
    
    }
    healthBar();
    checkWin();
}
const heal = (amount) => {
    if(game.target == 'none'){
        chat('No Target Selected');
        return;
    }else{
        chat(`Restoring ${amount} HP to ${game.target}`);
        game[game.target].HP += amount;
        if(game[game.target].HP > 20){
            game[game.target].HP = 20;
        }else{
            $(`.${game.target}`).html(game[game.target].HP);
        }
    }

}
const directDamage = (amount) => {
    if(game.targetOpponent == 'none'){
        chat('No Target Selected');
        return;
    }else{
        console.log(game[game.targetOpponent]);
        if(game[game.targetOpponent].Shield === true){
            removeShield();
            chat(`${game.targetOpponent}'s shield blocked all damage`);
        }else{
            chat(`Dealing ${amount} damage to ${game.targetOpponent}`);
            game[game.targetOpponent].HP -= amount;
            if(game.targetOpponent === 'player2' || game.targetOpponent === 'player3' || game.targetOpponent === 'player4'){
            $(`.${game.targetOpponent}HP`).html(game[game.targetOpponent].HP);
            }else{
                $(`.${game.targetOpponent}HP`).html(game[game.targetOpponent].HP);
            }
        }
        if(game[game.targetOpponent].HP <= 0){
            game[game.targetOpponent].alive = false
        }
        game.targetOpponent = 'none';
    }
    //update();
   
    healthBar();
    checkWin();
}
const directHeal = (amount) => {
    if(game.targetOpponent == 'none'){
        chat('No Target Selected');
        return;
    }else{
        
        chat(`Restoring ${amount} HP to ${game.targetOpponent}`);
        game[game.targetOpponent].HP += amount;
        if(game[game.targetOpponent].HP > 20){
            game[game.targetOpponent].HP = 20;
        }else{
            $(`.${game.targetOpponent}HP`).html(game[game.targetOpponent].HP);
        }
        
        game.targetOpponent = 'none';
    }
    //update();
    healthBar();
}

const applyShield = (target) => {
    removeShield();
    game[target].Shield = true;
    $(`.${target} .green`).addClass('blue');
    if(target === 'player2'){
        $('.green2').addClass('blue');
    }    
    if(target === 'player3'){   
        $('.green3').addClass('blue');
    }
    if(target === 'player4'){
        $('.green4').addClass('blue');
    }
    console.log(game[target]);
}
const removeShield = () =>{
    removeShieldVisual();
    game.player.Shield = false;
    game.player2.Shield = false;
    game.player3.Shield = false;
    game.player4.Shield = false;
    $('.blue').removeClass('blue');
}
    

//Gate Function
const setTargetOpponent = (target) => {
    removeSelectedClass();
    game.targetOpponent = target;
    console.log('target: ', game.targetOpponent);
    $(`.${target}`).addClass('selected');
    
}

//opponent function
const setTargetGate = (target) => {
    if(game.turnStep == 'gateAttack'){
    removeSelectedClass();
    game.target = target;
    console.log('target: ', game.target);
    $(`.${target}`).addClass('selected');
    }
}


// Dice Functions
const stopDiceRollAnimation = () => {
    clearInterval(diceRollInterval);
}
let diceRollInterval;

const diceRollAnimation = () => {
    if(game.dice.rolling === true){
        stopDiceRollAnimation();
        game.dice.rolling = false;
        action();
    }else{
        game.dice.rolling = true;
        setDiceResult(rng(1, 6));
    diceRollInterval = setInterval(() => {
        setDiceResult(rng(1, 6));
        ;
        console.log(game.dice.result)
    }, 100);
    }
    
}

const setDiceResult = (num) => {
    game.dice.result = num;
    $('.dice').html(game.dice.result);
}

//healthabr function
const healthBar = () => {
    $('.green').css('width', game.player.HP * 10 + '%');
    $('.red').css('width', (10 - game.player.HP) * 10 + '%');
    $('.green2').css('width', game.player2.HP * 10 + '%');
    $('.red2').css('width', (10 - game.player2.HP) * 10 + '%');
    $('.green3').css('width', game.player3.HP * 10 + '%');
    $('.red3').css('width', (10 - game.player3.HP) * 10 + '%');
    $('.green4').css('width', game.player4.HP * 10 + '%');
    $('.red4').css('width', (10 - game.player4.HP) * 10 + '%');

}

//cpu functions
const cpu = (activePlayer) => {
    if(game[activePlayer].alive === false){
        endTurn();
    }else{
    $('.your-turn').removeClass('your-turn');
    chat(`${activePlayer} is thinking...`);
    console.log(`.${game.activePlayer}`);
    $(`.${game.activePlayer}`).addClass('your-turn');
    console.log(game.turnStep);
    
    setTimeout(cpuGateAttack, 1000);
    setTimeout(endTurn,4000) 
    }
    
}

const cpuGateAttack = () => {
    autoChooseGate();
    
    damage(rng(1, 6));
    chat(`${game.activePlayer} attacks ${game.target}`);
    
    if(game[game.target].HP <= 0){
        chat(`${game.activePlayer} has destroyed ${game.target}`);
        setTimeout(cpuGateAbility, 1000);
    }
}

const cpuGateAbility = () => {   
    game[game.target].HP = 6;
    $(`.${game.target}`).html(game[game.target].HP);
    const gate = game.target;
    switch (gate) {
        case 'death':
            cpuDeathAbility();
            break;
        case 'illusion':
            cpuIllusionAbility();
            break;
        case 'restoration':
            cpuRestorationAbility();
            break;
        case 'destruction':
            cpuDestructionAbility();
            break;
    }
}

const cpuTurnStepTransition = () => {
    if (game.turnStep === 'gateAttack') {
        cpuGateAttack();
    } else if (game.turnStep === 'Abilities') {
        
        cpuGateAbility();
    }

}

//cpu gate Abilities

const cpuDeathAbility = () => {
    if (rng(1, 6) % 2 === 0) {
        game.targetOpponent = game.activePlayer;  
        directDamage(4);
        chat(`${game.activePlayer} takes 4 damage`);
    } else {
        autoChoose();
        if (game.target === 'gatekeeper') {
            damage(4);
            chat(`${game.activePlayer} deals 4 damage to the gatekeeper`);
        } else {    
            directDamage(4);
            chat(`${game.activePlayer} deals 4 damage to ${game.targetOpponent}`);
        }
    }
    
}
const cpuIllusionAbility = () => {
    const dice = rng(1, 6);
    if (dice % 2 !== 0) {
        applyShield(game.activePlayer);
        chat(`${game.activePlayer} gets a shield`);
    }
}
const cpuDestructionAbility = () => {
    if (rng(1, 6) % 2 === 0) {
        game.targetOpponent = game.activePlayer;
        directDamage(rng(1, 6) * 2);
        chat(`${game.activePlayer} takes a bunch of damage`);
    } else {
        game.targetOpponent = 'gatekeeper';
        directDamage(rng(1, 6) * 2);
        chat(`The gatekeeper takes a bunch of damage`);
    }
    
}
const cpuRestorationAbility = () => {
    if (rng(1, 6) % 2 === 0) {
        game.targetOpponent = game.activePlayer;
        directHeal(3);
        chat(`${game.activePlayer} heals 3 HP`);
    } else {
        game.targetOpponent = 'gatekeeper';
        directHeal(3);
        chat(`The gatekeeper heals 3 HP`);
}
}

//autochoose
const autoChoose = () => {
    const options = ['gatekeeper', 'player', 'player2', 'player3', 'player4'];
    const filteredOptions = options.filter(option => option !== game.activePlayer && game[option].alive);

    const randomIndex = Math.floor(Math.random() * filteredOptions.length);
    let goose = filteredOptions[randomIndex];
    console.log(goose);
    game.targetOpponent = goose
    console.log(game.targetOpponent);
    checkPlayerHP();
}

const checkPlayerHP = () => {

    let vulnerableplayers = [];
    if (game.player.HP <= 4 && game.player.alive === true) {
        vulnerableplayers.push('player');
    }
    if (game.player2.HP <= 4 && game.player2.alive === true) {
        vulnerableplayers.push('player2');
    }
    if (game.player3.HP <= 4 && game.player3.alive === true) {
        vulnerableplayers.push('player3');
    }
    if (game.player4.HP <= 4 && game.player4.alive === true) {
        vulnerableplayers.push('player4');
    }
    vulnerableplayers.sort((a, b) => game[a].HP - game[b].HP);

    console.log(vulnerableplayers);
    if(vulnerableplayers.length > 0){
        game.targetOpponent = vulnerableplayers[0];
    }
    
    if(game.gatekeeper.HP <= 4){
      game.targetOpponent = 'gatekeeper';
   }

   if(vulnerableplayers.length > 0){
    return true;
   }

}
const autoChooseGate = () => {
    const options = ['death', 'death',  'illusion', 'restoration', 'destruction', 'destruction'];
    const randomIndex = Math.floor(Math.random() * options.length);
    game.target = options[randomIndex];
    
    let lowHPGates = [];
    if (game.death.HP <= 5 && game.death.alive === true) {
        lowHPGates.push('death');
    }
    if (game.illusion.HP <= 5 && game.illusion.alive === true) {
        lowHPGates.push('illusion');
    }
    if (game.restoration.HP <= 5 && game.restoration.alive === true) {
        lowHPGates.push('restoration');
    }
    if (game.destruction.HP <= 5 && game.destruction.alive === true) {
        lowHPGates.push('destruction');
    }
    lowHPGates.sort((a, b) => game[a].HP - game[b].HP);
    
    if(lowHPGates.length > 0){
        game.target = lowHPGates[0];
    }

    if(game[game.activePlayer].HP < 8){
        
        if(rng(1,2)===1){
          game.target = 'restoration';  
        }else{
            game.target = 'illusion';
        }
        
    }
    if(game[game.activePlayer].Shield === true){
        game.target = 'destruction';
    }
    if(checkPlayerHP() === true){
        game.target = 'death';
    }
    if(game.gatekeeper.HP <= 4){
        game.target = 'death';
    }
    if(game[game.activePlayer].HP <= 4){
        game.target = 'restoration';
    }
    
    

}
//Check for win
const checkWin = () => {
    console.log('checking win');
    
    if(game.player2.HP <= 0 && game.player3.HP <= 0 && game.player4.HP <= 0 || game.gatekeeper.HP <= 0 || game.player.HP <= 0){
        gameOver();   
        console.log('win'); 
    }
    

}

const gameOver = (winorlose) => {
    game.game = 'over';
    game.turnStep = 'gameOver';
    game.activePlayer = 'none';
    $('.endscreen').css('display', 'flex');
    if(game.player2.HP <= 0 && game.player3.HP <= 0 && game.player4.HP <= 0){
        $('.endscreen h1').html('You Win');
        setStats(prevStats => ({
            ...prevStats,
            wins: prevStats.wins + 1
        }));
    }else if(game.player.HP <= 0){
        $('.endscreen h1').html('You Lose');
        setStats(prevStats => ({
            ...prevStats,
            losses: prevStats.losses + 1
        }));
    }else if(game.gatekeeper.HP <= 0 && game[game.activePlayer] === 'player'){
        $('.endscreen h1').html('You Win');
        setStats(prevStats => ({
            ...prevStats,
            wins: prevStats.wins + 1
        }));
    }else{
        $('.endscreen h1').html('You Lose');
        setStats(prevStats => ({
            ...prevStats,
            losses: prevStats.losses + 1
        }));
    
    }
    
}


    return (
        <div className='play'>
            <div className='opponents' >
                <div className='endcap onee'>0
                </div>
                <div className='opponent player2' onClick={()=>{setTargetOpponent('player2')}}>
                    <p>Player 2</p>
                    <div className='HP'>
                        <div className='green2' style={{width: HP.player2 * 10 + '%'}}>Shield</div>
                        <div className='red2' style={{width: (10 - HP.player2) * 10 + '%'}}>0</div>
                    </div>
                    <div className='player2HP'>
                    {HP.player2} HP
                    </div>
                </div>
                <div className='opponent player3' onClick={()=>{setTargetOpponent('player3')}}>
                    <p>Player 3</p>
                    <div className='HP'>
                        <div className='green3' style={{width: HP.player3 * 10 + '%'}}>Shield</div>
                        <div className='red3' style={{width: (10 - HP.player3) * 10 + '%'}}>0</div>
                    </div>
                    <div className='player3HP'>
                    {HP.player3} HP
                    </div>
                </div>
                <div className='opponent player4' onClick={()=>{setTargetOpponent('player4')}}>
                    <p>Player 4</p>
                    <div className='HP'>
                        <div className='green4' style={{width: HP.player4 * 10 + '%'}}>Shield</div>
                        <div className='red4' style={{width: (10 - HP.player4) * 10 + '%'}}>0</div>
                    </div>
                    <div className='player4HP'>
                    {HP.player4} HP
                    </div>
                </div>
                <div className='endcap twoe'>0
                </div>
            </div>
                            <div className='endscreen' onClick={reset}>
                                <h1>Game over</h1>
                            </div> 
            <div className='board'>
                <div className='img'>
                    <div className='fillerRow'></div>
                    <div className='topRow'>
                        <div className='death gate deathHP' onClick={()=>{setTargetGate('death')}}>{game.death.HP}</div>
                    </div>
                    <div className='middleRow'>
                        <div className='illusion gate illusionHP' onClick={()=>{setTargetGate('illusion')}}>{game.illusion.HP}</div>
                        <div className='fillerColumn'></div>
                        <div className='gatekeeper gatekeeperHP' onClick={()=>{setTargetOpponent('gatekeeper')}}>
                            {game.gatekeeper.HP}
                            
                        </div> 
                        <div className='fillerColumn'></div>
                        <div className='restoration gate restorationHP' onClick={()=>{setTargetGate('restoration')}}>{game.restoration.HP}</div>
                    </div>
                    <div className='bottomRow'>
                        <div className='destruction gate destructionHP' onClick={()=>{setTargetGate('destruction')}}>{game.destruction.HP}</div>
                    </div>
                </div>
                <div className='row'>
                    <div className='filler'></div>
                    <div className='stats'>
                        <div className='wins'>
                            W:
                            {stats.wins}
                        </div>
                        <div className='losses'>
                            L:
                            {stats.losses}
                        </div>

                    </div>
                    <div className='player'> 
                    <div className='HP'>
                        <div className='green' style={{width: HP.player * 10 + '%'}}>0</div>
                        <div className='red' style={{width: (10 - HP.player) * 10 + '%'}}>0</div>
                    </div>
                    <div className='playerHP'>
                        {HP.player} HP
                    </div>  
                    </div>
                    <div className='shield'>
                        <img className='shield-true hidden' src={shield} alt='shield'></img>
                        <img className='shield-false' src={noshield} alt='no shield'></img>
                    </div>
                    <div className='filler'></div>
                    <div onMouseDown={diceRollAnimation} className='dice'>
                    {game.dice.result}
                </div>
                </div>    
                
            </div>
              
             
            
            
            
            <div className='info'>
                <div className='chat'>
                    <h3>{game.chat}</h3>
                    

                </div>     
            </div>
             <button className='reset' onClick={reset}>Reset Board</button>
        </div>
    );
};



export default Play;
