import React, { useState, useEffect } from 'react';
import Rankings from './Rankings.jsx';
import Genesis from './Genesis.jsx';
import StatNav from './statTrackerNav.jsx';

const SeasonOneTwoThree = () => {
    const seasons = ['Season One', 'Season Two', 'Season Three'];
    const [selectedSeason, setSelectedSeason] = useState('Season One');

    useEffect(() => {
        setSelectedSeason('Season One');
    }, []);

    const handleSeasonChange = (event) => {
        setSelectedSeason(event.target.value);
    };

    return (
        <div className="rankingBox">
            <StatNav />
            <select value={selectedSeason} onChange={handleSeasonChange}>
                {seasons.map((season) => (
                    <option key={season} value={season}>
                        {season}
                    </option>
                ))}
            </select>
            <div className="row">
                {selectedSeason === 'Season One' && <Genesis season='seasonone' />}
                {selectedSeason === 'Season Two' && <Genesis season='seasontwo' />}
                {selectedSeason === 'Season Three' && <Genesis season='seasonthree' />}
                
            </div>
        </div>
    );
};

export default SeasonOneTwoThree;