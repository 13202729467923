import './css/rankings.css';
import React, { useState, useEffect } from 'react';
import { db } from '../firebase.js';
import { collection, getDocs } from "firebase/firestore";
import StatNav from './statTrackerNav.jsx';

const AllTime = () => {

    

    

    const [leaderboardData, setLeaderboardData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const q1 = collection(db, "stattracker", "leaderboards", "seasonone");
                const q2 = collection(db, "stattracker", "leaderboards", "seasontwo");
                const q3 = collection(db, "stattracker", "leaderboards", "seasonthree");
                const q4 = collection(db, "stattracker", "leaderboards", "seasonfour");
                const q5 = collection(db, "stattracker", "leaderboards", "seasonfive");
                const q6 = collection(db, "stattracker", "leaderboards", "seasonsix");
                const q6b = collection(db, "stattracker", "leaderboards", "submissions");
                const querySnapshot1 = await getDocs(q1);
                const querySnapshot2 = await getDocs(q2);
                const querySnapshot3 = await getDocs(q3);
                const querySnapshot4 = await getDocs(q4);
                const querySnapshot5 = await getDocs(q5);
                const querySnapshot6 = await getDocs(q6);
                const querySnapshot6b = await getDocs(q6b);
                const data1 = querySnapshot1.docs.map(doc => doc.data());
                const data2 = querySnapshot2.docs.map(doc => doc.data());
                const data3 = querySnapshot3.docs.map(doc => doc.data());
                const data4 = querySnapshot4.docs.map(doc => doc.data());
                const data5 = querySnapshot5.docs.map(doc => doc.data());
                const data6 = querySnapshot6.docs.map(doc => doc.data());
                const data7 = querySnapshot6b.docs.map(doc => doc.data());

                //combine all arrays into one
               const arrays = [data1, data2, data3, data4, data5, data6, data7];
               const merged = arrays.flat();

               const sliced = merged.map(obj => ({
                ...obj,
                count: obj.count.slice(0, 2)
            })); //create a copy of the array of objects
               

               //group objects by the name property
               const groupedByName = sliced.reduce((acc, obj) => {
                const {name, count} = obj;
                if (!acc[name]) {
                    acc[name] = [];
                }
                acc[name].push({...obj, count: [...count]}); //create a copy of the count array
                return acc;
            }, {});  
               
            //merge objects with the same name property
            const mergedArray = Object.keys(groupedByName).map(name => {
                const mergedObject = groupedByName[name].reduce((acc, obj) => {
                    acc.name = obj.name;
                    acc.count = acc.count.map((val, i) => val + obj.count[i]);
                    return acc;
                }, { name, count: new Array(groupedByName[name][0].count.length).fill(0) }); // Initialize count array with zeros
                return mergedObject;
            });
            
            //sort the array by the first element of the count array
            mergedArray.sort((a, b) => b.count[0] - a.count[0]);


            setLeaderboardData(mergedArray);

            } catch (error) {
                console.error('Error fetching data: ', error);
            }
        };

        fetchData();
    }, []);

    return (
        <div className="rankingBox">
            <StatNav />
            <div className="row">
                <table>
                    <thead>
                        <tr>
                            <th>NAME</th>
                            <th className='thRow '>
                                <div className='black'>POINTS</div>
                                <div className='black'>GAMES</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {leaderboardData.map(entry => (
                            <tr key={entry.id}>
                                <td>{entry.name}</td>
                                <td>
                                    {entry.count && (
                                        <div className='row stat'>
                                            {entry.count.map((number, index) => (
                                                <div key={index}>{number}</div>
                                            ))}
                                        </div>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};


export default AllTime;
