import React, { useState, useEffect } from 'react';
import StatNav from './statTrackerNav.jsx';
import MidSeason from './MidSeasons.jsx';

const SeasonFourFive = () => {
    const [selectedSeason, setSelectedSeason] = useState('seasonfour');
    const [styles, setStyles] = useState({ display: 'none' });

    const handleSeasonChange = (event) => {
        setSelectedSeason(event.target.value);
        setStyles({ display: 'none' });
    };

    useEffect(() => {
        // Set the selected season to 'seasonfour' when the component mounts
        setSelectedSeason('seasonfour');
    }, []);

    return (
        <div className='rankingBox'>
            <StatNav />
            <select value={selectedSeason} onChange={handleSeasonChange}>
                <option value="seasonfour">Season Four</option>
                <option value="seasonfive">Season Five</option>
            </select>
            {selectedSeason === 'seasonfour' && <MidSeason season="seasonfour" visible={<div className='black' > KKA</div> }/>}
            {selectedSeason === 'seasonfive' && <MidSeason season="seasonfive"  />}
        </div>
    );
};

export default SeasonFourFive;


