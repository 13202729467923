import React from 'react';
import { db } from '../firebase.js';
import { useEffect } from 'react';
import { useState } from 'react';
import { doc, onSnapshot, setDoc } from "firebase/firestore";
import $ from 'jquery';
import shield from '../img/shield.png';
import noshield from '../img/noshield.png';
import './css/play.css';
import './css/board.css';
import './css/effects.css';
import './css/room.css';




const Room = () => {
    const [data, setData] = useState({action: null,
        activePlayer: "player1",
        player1: [10, false, false],
        player2: [10, false, false],
        player3: [10, false, false],
        player4: [10, false, false],
        players: null, 
        name1: "",
        name2: "",
        name3: "",
        name4: "",
        gatekeeper: [10, false, true],
        death: [6, false, true],
        defense: [6, false, true],
        restoration: [6, false, true],
        destruction: [6, false, true],
        targetGate: null,
        targetOpponent: null,
        turnStep: 'gateAttack',
        chat: [],
        gameStatus: "active",
        winner:""
    });
    
    const [game, setGame] = useState({
        localPlayer: null,
        dice: {rolling: false, result: 1},
        
    });


    useEffect(() => {
        const unsub = onSnapshot(
            doc(db, "online", "room1"),
            { includeMetadataChanges: true },
            (doc) => {
                const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
                setData(doc.data());
                console.log(source, " data: ", doc.data());
            }
        );
        
        

        return () => {
            unsub();
        };
    }, []);

      

const resetBoard = () => {
    $('.hidden').removeClass('hidden');
    $('.red').removeClass('red');  
    $('.blue').removeClass('blue');
    $('.purple').removeClass('purple');
    $('.yellow').removeClass('yellow');
    $('.player1 p').text('');
    $('.player2 p').text('');
    $('.player3 p').text('');
    $('.player4 p').text('');

    setDoc(doc(db, "online", "room1"), {
        action: null,
        activePlayer: "player1",
        player1: [10, false, false],
        player2: [10, false, false],
        player3: [10, false, false],
        player4: [10, false, false],
        players: null, 
        name1: "",
        name2: "",
        name3: "",
        name4: "",
        gatekeeper: [10, false, true],
        death: [6, false, true],
        defense: [6, false, true],
        restoration: [6, false, true],
        destruction: [6, false, true],
        targetGate: null,
        targetOpponent: null,
        turnStep: 'gateAttack',
        chat: [],
        gameStatus: "active",
        winner:""
    });
    setGame({
        localPlayer: null,
        dice: {rolling: false, result: 1},
    });
    console.log('Board Reset');
}
    


//transitions


//Visual Functions

const addRedClass = () => {
    if (data.name1 !== "") {
        $('.player1').addClass('red');
        hidePlayer1ButtonAndInput();
        
    }
    
}
const addBlueClass = () => {
    if (data.name2 !== "") {
        $('.player2').addClass('blue');
        hidePlayer2ButtonAndInput();
    }
    
}
const addPurpleClass = () => {
    if (data.name3 !== "") {
        $('.player3').addClass('purple');
        hidePlayer3ButtonAndInput();
    }
    
}
const addYellowClass = () => {
    if (data.name4 !== "") {
        $('.player4').addClass('yellow');
        hidePlayer4ButtonAndInput();
    }
    
}

const removeRedClass = () => {
    if (data.name1 === "") {
        $('.player1').removeClass('red');
        showPlayer1ButtonAndInput();
    }
    
}
const removeBlueClass = () => {
    if (data.name2 === "") {
        $('.player2').removeClass('blue');
        showPlayer2ButtonAndInput();
    }
    
}
const removePurpleClass = () => {
    if (data.name3 === "") {
        $('.player3').removeClass('purple');
        showPlayer3ButtonAndInput();
    }
}
const removeYellowClass = () => {
    if (data.name4 === "") {
        $('.player4').removeClass('yellow');
        showPlayer4ButtonAndInput();
    }
}
const hidePlayer1ButtonAndInput = () => {
    $('.player1 button').addClass('hidden');
    $('.player1 input').addClass('hidden');
}
const hidePlayer2ButtonAndInput = () => {

    $('.player2 button').addClass('hidden');
    $('.player2 input').addClass('hidden');
}
const hidePlayer3ButtonAndInput = () => {
    $('.player3 button').addClass('hidden');
    $('.player3 input').addClass('hidden');
}
const hidePlayer4ButtonAndInput = () => {
    $('.player4 button').addClass('hidden');
    $('.player4 input').addClass('hidden');
}
const showPlayer1ButtonAndInput = () => {
    $('.player1 button').removeClass('hidden');
    $('.player1 input').removeClass('hidden');
}
const showPlayer2ButtonAndInput = () => {
    $('.player2 button').removeClass('hidden');
    $('.player2 input').removeClass('hidden');
}
const showPlayer3ButtonAndInput = () => {
    $('.player3 button').removeClass('hidden');
    $('.player3 input').removeClass('hidden');
}
const showPlayer4ButtonAndInput = () => {
    $('.player4 button').removeClass('hidden');
    $('.player4 input').removeClass('hidden');
}

useEffect(() => {
if(data.player1[1] === true){
    $('.player1 .green').addClass('blue');
    $('.player1 .green').removeClass('green');
}else{
    $('.player1 .blue').addClass('green');
    $('.player1 .blue').removeClass('blue');
   

}
if(data.player2[1] === true){
    $('.player2 .green2').addClass('blue');
    $('.player2 .green2').removeClass('green2');
}else{
    $('.player2 .blue').addClass('green2');
    $('.player2 .blue').removeClass('blue');
    
}
if(data.player3[1] === true){
    $('.player3 .green3').addClass('blue');
    $('.player3 .green3').removeClass('green3');

}else{
    $('.player3 .blue').addClass('green3');
    $('.player3 .blue').removeClass('blue');
    
}
if(data.player4[1] === true){
    $('.player4 .green4').addClass('blue');
    $('.player4 .green4').removeClass('green4');
}else{
    $('.player4 .blue').addClass('green4');
    $('.player4 .blue').removeClass('blue');
    
}



}, [data.player1[1], data.player2[1], data.player3[1], data.player4[1]]);

useEffect(() => {
    if (data.name1 || data.name2 || data.name3 || data.name4) {
        addRedClass();
        addBlueClass();
        addPurpleClass();
        addYellowClass();
        removeRedClass();
        removeBlueClass();
        removePurpleClass();
        removeYellowClass();
        removeYouTurnClass();
        $(`.${data.activePlayer}`).addClass('yourturn');
        if(data.activePlayer === game.localPlayer){
            $('.dice').addClass('yourturn');
        }
    }
}, [ data.name1, data.name2, data.name3, data.name4, data.activePlayer]);

useEffect(() => {
    removeSelectedClass();
    $(`.${data.targetGate}`).addClass('selected');
}, [ data.targetGate]);

useEffect(() => {
    removeSelectedClass();
    $(`.${data.targetOpponent}`).addClass('selected');
}, [data.targetOpponent]);

useEffect(() => {
    const livingPlayers = [];
    if(data.player1[0] > 0 && data.player1[2] === true){
        livingPlayers.push('player1');
    }
    if(data.player2[0] > 0 && data.player2[2] === true){
        livingPlayers.push('player2');
    }
    if(data.player3[0] > 0 && data.player3[2] === true){
        livingPlayers.push('player3');
    }
    if(data.player4[0] > 0 && data.player4[2] === true){
        livingPlayers.push('player4');
    }
    if(livingPlayers.length === 1){
        $('.endscreen').css('display', 'flex');
        
        switch(livingPlayers[0]){
            case 'player1':
                $('.endscreen h1').text(`${$('.player1 p').text()} wins!`);
                $('.endscreen').css('background-color', 'red');
                break;  
            case 'player2':
                $('.endscreen h1').text(`${$('.player2 p').text()} wins!`);
                $('.endscreen').css('background-color', 'blue');
                break;
            case 'player3':
                $('.endscreen h1').text(`${$('.player3 p').text()} wins!`);
                $('.endscreen').css('background-color', 'purple');
                break;
            case 'player4':
                $('.endscreen h1').text(`${$('.player4 p').text()} wins!`);
                $('.endscreen').css('background-color', 'yellow');
                break;
        }
    }
    if(data.gatekeeper[0] <= 0 && $('.endscreen').css('display') === 'none'){
        
            gameEnd();
      
    }
    

}, [data.player1[0], data.player2[0], data.player3[0], data.player4[0], data.gatekeeper[0]]);



function removeSelectedClass() {
    // Adding a small delay to ensure asynchronous behavior
    $('.selected').removeClass('selected');
}
function removeYouTurnClass() {
    $('.yourturn').removeClass('yourturn');
}

const removeShieldVisual = () => {
console.log('removing shield visual');
$('.shield-false').removeClass('hidden');
$('.shield-true').addClass('hidden');
$('.blue').removeClass('blue');
}
const addShieldVisual = () => {
console.log('adding shield visual');
$('.shield-true').removeClass('hidden');
$('.shield-false').addClass('hidden');

}

// Dice Functions
const rng = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

const stopDiceRollAnimation = () => {
    clearInterval(diceRollInterval);
}
let diceRollInterval;

const diceRollAnimation = () => {
    if(game.dice.rolling === true){
        stopDiceRollAnimation();
        game.dice.rolling = false;
        action();
    }else{
        game.dice.rolling = true;
        setDiceResult(rng(1, 6));
    diceRollInterval = setInterval(() => {
        setDiceResult(rng(1, 6));
        ;
    }, 100);
    }
    
}

const setDiceResult = (num) => {
    game.dice.result = num;
    $('.dice').html(game.dice.result);
}

//action
const action = () => {
    console.log(game.dice.result);
   if(data.activePlayer === game.localPlayer){
    if(data.turnStep === 'traitRoll'){
       traitRoll();
   }
    if(data.turnStep === 'gateAttack'){
        gateAttack();
    }
    if(data.turnStep === 'abilities'){
        abilities();
    }
   }
}

//turnsteps
const gameEnd = () => { 
        $('.endscreen').css('display', 'flex');
        switch(data.activePlayer){
            case 'player1':
                $('.endscreen h1').text(`${$('.player1 p').text()} wins!`);
                $('.endscreen').css('background-color', 'red');
                break;  
            case 'player2':
                $('.endscreen h1').text(`${$('.player2 p').text()} wins!`);
                $('.endscreen').css('background-color', 'blue');
                break;
            case 'player3':
                $('.endscreen h1').text(`${$('.player3 p').text()} wins!`);
                $('.endscreen').css('background-color', 'purple');
                break;
            case 'player4':
                $('.endscreen h1').text(`${$('.player4 p').text()} wins!`);
                $('.endscreen').css('background-color', 'yellow');
                break;
        }
        
}

const endTurn = () => {
    if(data[data.targetGate][0] <= 0){setGateHP(data.targetGate, 6);}
        
    setTurnStep('gateAttack');
    const livingPlayers = [];
    if(data.player1[0] > 0 && data.player1[2] === true){
        livingPlayers.push('player1');
    }
    if(data.player2[0] > 0 && data.player2[2] === true){
        livingPlayers.push('player2');
    }
    if(data.player3[0] > 0 && data.player3[2] === true){
        livingPlayers.push('player3');
    }
    if(data.player4[0] > 0 && data.player4[2] === true){
        livingPlayers.push('player4');
    }

    console.log(livingPlayers);

    const currentIndex = livingPlayers.indexOf(data.activePlayer);
    const nextIndex = (currentIndex + 1) % livingPlayers.length;
    const nextPlayer = livingPlayers[nextIndex];
    const lastPlayer = livingPlayers[livingPlayers.length - 1];
    const activePlayer = (currentIndex === livingPlayers.length - 1) ? livingPlayers[0] : nextPlayer;
    setActivePlayer(activePlayer);
}

const traitRoll = () => {console.log('traitRoll');}
const gateAttack = () => {
    setChat('Attack a Gate');
    if(data.targetGate === null){
        setChat('select a gate to attack');
    }else {
    setGateHP(data.targetGate, data[data.targetGate][0] - game.dice.result);
    if(data[data.targetGate][0] - game.dice.result <= 0){
        
        setTurnStep('abilities');
    }else{
        endTurn();
    }
    }
    
}
const abilities = () => {

    setChat('Roll for your ability');
    switch(data.targetGate){
        case 'death':
            death();
            break;
        case 'destruction':
            destruction();
            break;
        case 'restoration':
            restoration();
            break;
        case 'defense':
            defense();
            break;
    
        
}
endTurn();
}

const death = () => {
    if (game.dice.result % 2 === 1) {
        if(data.targetOpponent === null){
            if(data.gatekeeper[0] - 4 <= 0 ){
                    gameEnd();
            }    
            setPlayerHP('gatekeeper', data.gatekeeper[0] - 4);
                
        }else{
            if(data[data.targetOpponent][1] === true){
            setPlayerShield(data.targetOpponent,false);
            }else{
                if(data.gatekeeper[0] - 4 <= 0 && data.targetOpponent === 'gatekeeper'){
                    gameEnd();
                }   
                setPlayerHP(data.targetOpponent, data[data.targetOpponent][0] - 4);
                
            }
        }
        
    }else{
        if(data[data.activePlayer][1] === true){
            setPlayerShield(data.activePlayer,false);
        }else{ 
            setPlayerHP(data.activePlayer, data[data.activePlayer][0] - 4);
        }
        
    
    }
    setTargetOpponent(null);
}

const destruction = () => {
    if (game.dice.result % 2 === 1) {
        if(data.gatekeeper[0] - game.dice.result*2 <= 0 ){
            gameEnd();
     }   
        setGateHP('gatekeeper', data.gatekeeper[0] - game.dice.result*2);
    }else{
        if(data[data.activePlayer][1] === true){
            setPlayerShield(data.activePlayer,false);
        }else{
           setPlayerHP(data.activePlayer, data[data.activePlayer][0] - game.dice.result*2); 
        }
        
    
    }
}

const restoration = () => {
    if (game.dice.result % 2 === 1) {
        setPlayerHP(data.activePlayer, data[data.activePlayer][0] + 3);
    }else{
        
        setGateHP('gatekeeper', data.gatekeeper[0] + 3 );
    }
}

const defense = () => {
    if (game.dice.result % 2 === 1) {
        setPlayerShield(data.activePlayer,true);
    }
    
}


//board update functions

const setAction = (step) => {
        setDoc(doc(db, "online", "room1"), {
            action: step
        }, { merge: true });
}
const setGateHP = (gate, hp) => {
    setDoc(doc(db, "online", "room1"), {
        [gate]: [hp, data[gate][1], data[gate][2]]
    }, { merge: true });
}
const setPlayerHP = (player, hp) => {
    setDoc(doc(db, "online", "room1"), {
        [player]: [hp, data[player][1], data[player][2]]
    }, { merge: true });

}
const setPlayerShield = (player,shield) => {
    setDoc(doc(db, "online", "room1"), {
        [player]: [data[player][0], shield, data[player][2]]
    }, { merge: true });


}
const setGameStatus = (status) => {
    setDoc(doc(db, "online", "room1"), {
        gameStatus: status
    }, { merge: true });
}
const setActivePlayer = (player) => {
    setDoc(doc(db, "online", "room1"), {
        activePlayer: player
    }, { merge: true });
}
const setTargetGate = (gate) => {
    if(data.turnStep === 'gateAttack'){
        setDoc(doc(db, "online", "room1"), {
        targetGate: gate
    }, { merge: true });
    }
    
}
const setTargetOpponent = (opponent) => {
    setDoc(doc(db, "online", "room1"), {
        targetOpponent: opponent
    }, { merge: true });
}
const setTurnStep = (step) => {
    setDoc(doc(db, "online", "room1"), {
        turnStep: step
    }, { merge: true });
}
const setChat = (message) => {
    setDoc(doc(db, "online", "room1"), {
        chat: [...data.chat, message]
    }, { merge: true });
}
const setName1 = (name) => {
    setDoc(doc(db, "online", "room1"), {
        name1: name
    }, { merge: true });
}
const setName2 = (name) => {
    setDoc(doc(db, "online", "room1"), {
        name2: name
    }, { merge: true });
}
const setName3 = (name) => {
    setDoc(doc(db, "online", "room1"), {
        name3: name
    }, { merge: true });
}
const setName4 = (name) => {
    setDoc(doc(db, "online", "room1"), {
        name4: name
    }, { merge: true });
}
const setPlayer1 = (hp, shield, status) => {
    setDoc(doc(db, "online", "room1"), {
        player1: [hp, shield, status]
    }, { merge: true });
}
const setPlayer2 = (hp, shield, status) => {
    setDoc(doc(db, "online", "room1"), {
        player2: [hp, shield, status]
    }, { merge: true });
}
const setPlayer3 = (hp, shield, status) => {
    setDoc(doc(db, "online", "room1"), {
        player3: [hp, shield, status]
    }, { merge: true });
}
const setPlayer4 = (hp, shield, status) => {
    setDoc(doc(db, "online", "room1"), {
        player4: [hp, shield, status]
    }, { merge: true });
}
const setWinner = (winner) => {
    setDoc(doc(db, "online", "room1"), {
        winner: winner
    }, { merge: true });
}




    return (
        <div className='theRoom'>
            {data &&(
            <div className='playerSelect'>
                

               

               
                
            </div>
            )}
            {data && (
                <div className='play'>
                {//oppoents
                }
            <div className='opponents' >
                <div className='endcap onee'>
                </div>
                <div className='opponent player1' onClick={()=>{
                    if(game.localPlayer === data.activePlayer && game.dice.rolling === false){
                    setTargetOpponent('player1');
                    }
                }}> 
                    <p>{data.name1}</p><button onClick={() => {
                        if(game.localPlayer === null){
                            setName1(document.querySelector('.player1Name').value);   
                            setPlayer1(10, false, true);
                            game.localPlayer = 'player1';
                        }
                    
                }}>Lock IN</button>
                <input type="text" className="player1Name" placeholder="Name" />
                    <div className='HP'>
                        <div className='green' style={{width: data.player1[0] * 10 + '%'}}>Shield</div>
                        <div className='red' style={{width: (10 - data.player1[0]) * 10 + '%'}}></div>
                    </div>
                    <div className='player2HP'>
                     {data.player1[0]}HP
                    </div>
                </div>
                <div className='opponent player2' onClick={()=>{
                    if(game.localPlayer === data.activePlayer && game.dice.rolling === false){
                        setTargetOpponent('player2');
                    }
                }}>
                    <p>{data.name2}</p> <button onClick={() => {
                        if(game.localPlayer === null){
                            setName2(document.querySelector('.player2Name').value);
                            setPlayer2(10, false, true);
                            game.localPlayer = 'player2';
                        }
                }}>Lock IN</button>
                <input type="text" className="player2Name" placeholder="Name" />
                    <div className='HP'>
                        <div className='green2' style={{width: data.player2[0] * 10 + '%'}}>Shield</div>
                        <div className='red2' style={{width: (10 - data.player2[0]) * 10 + '%'}}></div>
                    </div>
                    <div className='player2HP'>
                     {data.player2[0]}HP
                    </div>
                </div>
                <div className='opponent player3' onClick={()=>{
                    if(game.localPlayer === data.activePlayer && game.dice.rolling === false){
                        setTargetOpponent('player3');
                    }
                    
                }}>
                    <p>{data.name3}</p> <button onClick={() => {
                        if(game.localPlayer === null){
                            setName3(document.querySelector('.player3Name').value);
                            setPlayer3(10, false, true);
                            game.localPlayer = 'player3';
                        }
                }}>Lock IN</button>
                <input type="text" className="player3Name" placeholder="Name" />
                    <div className='HP'>
                    <div className='green3' style={{width: data.player3[0] * 10 + '%'}}>Shield</div>
                        <div className='red3' style={{width: (10 - data.player3[0]) * 10 + '%'}}></div>
                    </div>
                    <div className='player3HP'>
                    {data.player3[0]}HP
                    </div>
                </div>
                <div className='opponent player4' onClick={()=>{
                    if(game.localPlayer === data.activePlayer && game.dice.rolling === false){
                        setTargetOpponent('player4');
                    }
                }} >
                    <p>{data.name4}</p><button onClick={() => {
                        if(game.localPlayer === null){
                            setName4(document.querySelector('.player4Name').value);
                            setPlayer4(10, false, true);
                            game.localPlayer = 'player4';
                        }
                }}>Lock IN</button>
                <input type="text" className="player4Name" placeholder="Name" />
                    <div className='HP'>
                    <div className='green4' style={{width: data.player4[0] * 10 + '%'}}>Shield</div>
                    <div className='red4 ' style={{width: (10 - data.player4[0]) * 10 + '%'}}></div>
                    </div>
                    <div className='player4HP'>
                    {data.player4[0]}HP
                    </div>
                </div>
                <div className='endcap twoe'>0
                </div>
            </div>

            {//endscreen
                }
                            <div className='endscreen' onClick={()=>{
                                $('.endscreen').css('display', 'none');
                                resetBoard();
                            }}>
                                <h1>Game over</h1>
                            </div> 


                {//board design
                }
            <div className='board'>
                <div className='img'>
                    <div className='fillerRow'></div>
                    <div className='topRow'>

                        {//death gate
                }
                        <div className='death gate deathHP' onClick={()=>{
                            if(game.localPlayer === data.activePlayer && game.dice.rolling === false){
                                setTargetGate('death');
                            }
                }} >{data.death[0]}</div>
                    </div>
                    <div className='middleRow'>

                        {//defense gate
                }
                        <div className='defense illusion gate illusionHP' onClick={()=>{
                            if(game.localPlayer === data.activePlayer && game.dice.rolling === false){
                                setTargetGate('defense');
                            }
                }} >{data.defense[0]}</div>
                        <div className='fillerColumn'></div>

                        {//gatekeeper
                }
                        <div className='gatekeeper gatekeeperHP' onClick={()=>{
                            if(game.localPlayer === data.activePlayer && data.turnStep === 'abilities' && game.dice.rolling === false){
                                setTargetOpponent('gatekeeper');
                            }
                }} >
                            {data.gatekeeper[0]}
                        </div> 
                        <div className='fillerColumn'></div>


                        {//restoration gate
                }
                        <div className='restoration gate restorationHP'onClick={()=>{
                            if(game.localPlayer === data.activePlayer && game.dice.rolling === false){
                                setTargetGate('restoration');
                            }
                }}  >{data.restoration[0]}</div>
                    </div>

                    <div className='bottomRow'>


                        {//destruction gate
                }
                        <div className='destruction gate destructionHP'onClick={()=>{
                            if(game.localPlayer === data.activePlayer && game.dice.rolling === false){
                                setTargetGate('destruction');
                            }
                }}  > {data.destruction[0]} </div>
                    </div>
                </div>
                
                    <div className='filler'></div>
                    
                    
                     
                
            </div>
              
             
             
             
            <div className='info roomInfo'>
                <div className='chat'>
                    <div className='chatbox'>
                        {data.chat.map((message, index) => {
                            return <div key={index} className='message'>{message}</div>
                        })}
                        </div>
                        <div className='input'>
                            <input type="text" placeholder="Type a message" onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    setChat(document.querySelector('.input input').value)
                                    document.querySelector('.input input').value = '';
                                    const chatbox = document.querySelector('.chatbox');
                                    setTimeout(() => {
                                        chatbox.scrollTop = chatbox.scrollHeight;
                                    }, 100);
                                }
                            }} />
                    <button onClick={()=>{
                        setChat(document.querySelector('.input input').value)
                            document.querySelector('.input input').value = '';
                            const chatbox = document.querySelector('.chatbox');
                            setTimeout(() => {
                                chatbox.scrollTop = chatbox.scrollHeight;
                            }, 100);
                                        
                }} className='send'>Send</button>
                        
                  
                 </div>  
                </div>
                  <div onMouseDown={()=>{if(game.localPlayer === data.activePlayer){diceRollAnimation()}}} className='dice roomDice'>{game.dice.result}
                </div>  
            </div>
             <button className='reset roomReset' onClick={resetBoard}>Reset Board</button>
                </div>
                )}
        </div>
        
    );
};

export default Room;