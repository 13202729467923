
import React from 'react';
import { db } from '../firebase.js';
import { useEffect } from 'react';
import { auth } from '../firebase.js';
import { useState } from 'react';
import { doc, onSnapshot, setDoc } from "firebase/firestore";
import './css/play.css';
import './css/board.css';
import './css/effects.css';
import './css/room.css';
import { onAuthStateChanged } from 'firebase/auth';

const Chat = () => {
    

    const [data, setData] = useState(null);
    useEffect(() => {
        const unsub = onSnapshot(
            doc(db, "online", "room2"),
            { includeMetadataChanges: true },
            (doc) => {
                const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
                console.log(source, " data: ", doc.data());
                setData(doc.data());
                console.log(source, " data: ", doc.data());
            }
        );

        return () => {
            unsub();
        };
    }, []);

    useEffect(() => {
        const chatbox = document.querySelector('.gkchatbox');
        setTimeout(() => {
            chatbox.scrollTop = chatbox.scrollHeight;
        }, 300);
    }, [data]);

    const setChat = (message) => {
        const currentUser = auth.currentUser;
        if(currentUser !== null){
            const userName = auth.currentUser.email.split('@')[0];
            setDoc(doc(db, "online", "room2"), {
            chat: [...(data?.chat || []), message + ' - ' + userName]
        }, { merge: true });
        }else{
            alert('You must be signed in to chat');
        }
        
    }

    return (
        <div className='info roomInfo gkroom'>
            <div className='gkchat'>
                <div className='gkchatbox'>
                    {data?.chat.map((message, index) => {
                        const chatbox = document.querySelector('.gkchatbox');
                        
                        return <div key={index} className='message'>{message}</div>
                        
                    })}
                </div>
                <div className='gkinput'>
                    <input type="text" placeholder="Type a message" onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            setChat(document.querySelector('.gkinput input').value)
                            document.querySelector('.gkinput input').value = '';
                            const chatbox = document.querySelector('.gkchatbox');
                            setTimeout(() => {
                                chatbox.scrollTop = chatbox.scrollHeight;
                            }, 300);
                        }
                    }} />
                    <button onClick={() => {
                        setChat(document.querySelector('.gkinput input').value)
                        document.querySelector('.gkinput input').value = '';
                        const chatbox = document.querySelector('.gkchatbox');
                        setTimeout(() => {
                            chatbox.scrollTop = chatbox.scrollHeight;
                        }, 300);

                    }} className='send'>Send</button> 
                    
                    
                </div>
                <div className='gkname'>

                </div> 
            </div>
        </div>
    );
};

export default Chat;