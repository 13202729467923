import React from 'react';
import $ from 'jquery';

import './css/homepage.css';

import wingart1 from '../img/wingart.png';
import wingart2 from '../img/wingart2.png';
import mc from '../img/package art widescreen.PNG';
import playnow from '../img/playnow.png';
import leaderboards from '../img/leaderboards.PNG';
import hallofhype from '../img/hallofhype.png';
import discord from '../img/discord.png';
import stats from '../img/Stat Tracker.png';

import Slideshow from './Slideshow.jsx';
import Window from './Window.jsx';

function Homepage() {

    const slides = [
        {image: mc},
        {image: discord}
      ];

    const redirect = () => {
        window.open('https://stats.sender.net/forms/e9q6Je/view', '_blank');
        console.log('redirected');
    }

  return (
    <div className='homepage'>
        <div className='container'>
                <div className='row'>
                    <div className="wing desktop" >
                        <img src={wingart1} className='content' alt="Logo"></img>
                    </div>
                    <div className="news" onClick={redirect}>
                        <Slideshow />
                    </div>
                    <div className="wing desktop">
                        <img src={wingart2} className='content' alt="Logo"></img>
                    </div>
                    

                </div>
                
                <div className='row'>
                    <div className='column desktop'>
                        <Window type='frame'
                         frame='https://www.instagram.com/gatekeepergame.co/embed' /></div>
                    <div className='column'>
                        <div className='row'>
                            <div className='column'>
                                <Window type='link' color='#ff8800' image={playnow} redirect="/play"/>
                                <Window type='link' color='#6cc431' image={leaderboards} redirect="/leaderboards"/>
                            </div>
                            <div className='column'>
                                <Window type='link' color='#508fee' image={hallofhype} redirect="https://youtube.com/playlist?list=PLPu944ZYLFGfWK_pcJX_u1tl6Z8I2qjRP&si=mZ-PxmO-yQWT0ypO"/>
                                <Window type='link' color='white' image={stats} redirect="/pointkeeper" />
                            </div>
                        </div>
                    </div>
                    
                    
                </div>
                <div className='row mobile'>
                        <Window type='frame'
                         frame='https://www.instagram.com/gatekeepergame.co/embed' />
                         <Window type='frame'
                            frame='https://www.youtube.com/embed/vKD1CPE0cMM?si=50DiWBG1b_0CJXjS' />
                                </div>
        </div>

    </div>
    
  );
}

export default Homepage;
