import React from 'react';

import './css/navbar.css';

const Navbar = () => {
    return (
       
        <div className="navbar">
            <a href="/">Home</a> 
            <a href="/play">Play</a>
            <a target='_blank' href="https://youtu.be/vKD1CPE0cMM?si=HjeOBjCxglYoyWE-">How To Play</a>
            <a href="/leaderboards">Leaderboards</a>
            <a target='_blank' href="https://youtube.com/playlist?list=PLPu944ZYLFGfWK_pcJX_u1tl6Z8I2qjRP&si=mZ-PxmO-yQWT0ypO">Hall Of Hype</a>     
            <a target='_blank' href="https://www.youtube.com/@gatekeeperthegame/featured">Youtube</a>
        </div>
    );
};

export default Navbar;
