import React, { useState, useContext, useEffect } from 'react';
import './css/rankings.css';

const StatNav = (props) => {
    
    const handleClickS6 = () => {
        window.location.href = '/seasonsix';
        

    };
    const handleClickS4 = () => {
        window.location.href = '/seasonfourfive';
        
    };
    const handleClickS1 = () => {
        window.location.href = '/seasononetwothree';
        
    };
    const handleClickAT = () => {
        window.location.href = '/allTimeRankings';
        
    };
    const handleClickSUB = () => {
        window.location.href = '/pointkeeper';
        
    };
    const handleClickPP = () => {
        window.location.href = '/playerProfiles';
    }



    return (
        <div className="rankingBox">
            <div>
                <div className="rankingNav">
                    <div onClick={handleClickSUB}>StatTracker</div>
                    <div onClick={handleClickS6}>Season 6</div>
                    <div onClick={handleClickS4}>Season 4-5</div>
                    <div onClick={handleClickS1}>Seasons 1-3</div>
                    <div onClick={handleClickAT}>ALL TIME</div>
                    <div onClick={handleClickPP}>Player Profiles</div>
                </div>
            </div>
        </div>
    );
};

export default StatNav;