import './css/rankings.css';
import React, { useState, useEffect } from 'react';
import { db } from '../firebase.js';
import { collection, getDocs } from "firebase/firestore";
import StatNav from './statTrackerNav.jsx';

const SeasonSix = (props) => {
    const [leaderboardData, setLeaderboardData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const q = collection(db, "stattracker", "leaderboards", props.season);
                const querySnapshot = await getDocs(q);
                const data = querySnapshot.docs.reduce((accumulator, doc) => {
                    const { id, name, count } = doc.data();
                    const existingEntryIndex = accumulator.findIndex(entry => entry.name === name);
                    if (existingEntryIndex !== -1) {
                        accumulator[existingEntryIndex].count = accumulator[existingEntryIndex].count.map((number, index) => number + count[index]);
                    } else {
                        accumulator.push({ id, name, count });
                    }
                    return accumulator;
                }, []);

                function sortData(array) {
                    console.log('array:', array);
                    array.sort((a, b) => {
                        if (a.count && b.count) { // Check if both 'count' arrays exist
                            return b.count[0] - a.count[0]; // Sort based on count[0] property
                        } else {
                            return 0; // If either 'count' array is missing, maintain the current order
                        }
                    });
                    console.log('sorted array:', array);
                    return array;
                }
                

                setLeaderboardData(sortData(data));
            } catch (error) {
                console.error('Error fetching data: ', error);
            }
        };

        fetchData();
    }, []);

    return (
        <div className="rankingBox">
            <div className="tableContainer">
                <table>
                    <thead>
                        <tr>
                            <th>NAME</th>
                            <th className='thRow '>
                                <div className='black'>POINTS</div>
                                <div className='black'>GAMES</div>
                                <div className='purple'>WINS</div>
                                <div className='orange'>OGC</div>
                                <div className='red'>RED</div>
                                <div className='blue'>BLUE</div>
                                <div className='black'>BLACK</div>
                                <div className='green'>GREEN</div>
                                <div className='orange'>CGC</div>
                                <div className='blue'>RS</div>
                                <div className='black'>KILLS</div>
                                <div className='orange'>PK</div>
                                <div className='purple'>GKK</div>
                                <div className='red'>DOM</div>
                                <div className='green'>JP</div>
                                <div className='orange'>DR</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {leaderboardData.map(entry => (
                            <tr key={entry.id}>
                                <td>{entry.name}</td>
                                <td>
                                    {entry.count && (
                                        <div className='row stat'>
                                            {entry.count.map((number, index) => (
                                                <div key={index}>{number}</div>
                                            ))}
                                        </div>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default SeasonSix;


