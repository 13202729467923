import React, { useState, useContext } from 'react';
import '../css/rankings.css';

import Rankings from '../Rankings.jsx';

const Submissions = () => {

    

    return (
            <div className="rankingBox">
                <Rankings/>
                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSd2KnkY2LAcesQ-MkDpr_dm1-j7FQCWiP2SrAEsosBxhCzRyg/viewform"></iframe>
            </div>
        
    );
};

export default Submissions;