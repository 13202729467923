import './css/profiles.css';
import StatNav from './statTrackerNav.jsx';
import { db } from '../firebase.js';
import { collection, getDocs, query } from "firebase/firestore";
import React, { useState, useEffect } from 'react';


const Profiles = () => {
    

    const [leaderboardData, setLeaderboardData] = useState([]);

useEffect(() => {
    const fetchData = async () => {
        try {
            const q = collection(db, "stattracker", "leaderboards", 'submissions');
            const querySnapshot = await getDocs(q);
            const data = querySnapshot.docs.reduce((accumulator, doc) => {
                const { id, name, count } = doc.data();
                const existingEntryIndex = accumulator.findIndex(entry => entry.name === name);
                if (existingEntryIndex !== -1) {
                    accumulator[existingEntryIndex].count = accumulator[existingEntryIndex].count.map((number, index) => number + count[index]);
                } else {
                    accumulator.push({ id, name, count });
                }
                return accumulator;
            }, []);

            function sortData(array) {
                console.log('array:', array);
                array.sort((a, b) => {
                    if (a.count && b.count) { // Check if both 'count' arrays exist
                        return b.count[0] - a.count[0]; // Sort based on count[0] property
                    } else {
                        return 0; // If either 'count' array is missing, maintain the current order
                    }
                });
                console.log('sorted array:', array);
                return array;
            }
            

            setLeaderboardData(sortData(data));
        } catch (error) {
            console.error('Error fetching data: ', error);
        }
    };

    fetchData();
}, []);

    leaderboardData.map((data) => {
        console.log(data);
    });


    return (
        <div className='profile' >
            Player Profiles
            <div className='name'><h3>Name</h3>{leaderboardData[0].name}</div>
            <div className='points'><h3>Points</h3>{leaderboardData[0].count[0]}</div>
            <div className='games'><h3>Games</h3>{leaderboardData[0].count[1]}</div>
            <div className='wins'><h3>Wins</h3>{leaderboardData[0].count[2]}</div>
            <div className='ogc'><h3>OGC</h3>{leaderboardData[0].count[3]}</div>
            <div className='red'><h3>Red</h3>{leaderboardData[0].count[4]}</div>
            <div className='blue'><h3>Blue</h3>{leaderboardData[0].count[5]}</div>
            <div className='black'><h3>Black</h3>{leaderboardData[0].count[6]}</div>
            <div className='green'><h3>Green</h3>{leaderboardData[0].count[7]}</div>
            <div className='cgc'><h3>CGC</h3>{leaderboardData[0].count[8]}</div>
            <div className='rs'><h3>RS</h3>{leaderboardData[0].count[9]}</div>
            <div className='kills'><h3>Kills</h3>{leaderboardData[0].count[10]}</div>
            <div className='pk'><h3>PK</h3>{leaderboardData[0].count[11]}</div>
            <div className='gkk'><h3>GKK</h3>{leaderboardData[0].count[12]}</div>
            <div className='dom'><h3>DOM</h3>{leaderboardData[0].count[13]}</div>
            <div className='jp'><h3>JP</h3>JP{leaderboardData[0].count[14]}</div>
            <div className='dr'><h3>DR</h3>DR{leaderboardData[0].count[15]}</div>
        </div>
    );
};


export default Profiles;