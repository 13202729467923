import React, {useState, useEffect} from 'react';
import $ from 'jquery';
import { auth, db } from './firebase.js';
import { doc, setDoc, addDoc, collection } from 'firebase/firestore';
import { authChange} from './firebase.js';

import logo from './img/gk logo.png';
import logo2 from './img/gk logo2.png';
import genie from './img/geniewashere.png';
import genie2 from './img/geniewashere2.png';

import './App.css';

import Navbar from './components/navbar.jsx';
import Homepage from './components/homepage.jsx';
import Leaderboards from './components/Leaderboards.jsx';
import Rankings from './components/Rankings.jsx'; 
import Season6 from './components/Rankings/season6.jsx';
import Season5 from './components/Rankings/season5.jsx';
import Season4 from './components/Rankings/season4.jsx';
import Season3 from './components/Rankings/season3.jsx';
import Season2 from './components/Rankings/season2.jsx';
import Season1 from './components/Rankings/season1.jsx';
import SeasonOneTwoThree from './components/SeasonsOneTwoThree.jsx';
import SeasonFourFive from './components/SeasonFourFive.jsx'
import SeasonSixAB from './components/SeasonSixAB.jsx';
import AllTime from './components/alltime.jsx';
import Characters from './components/Rankings/characters.jsx';
import Submissions from './components/Rankings/submissions.jsx';
import Casual from './components/Rankings/casual.jsx';
import Play from './components/Play.jsx';
import Pointkeeper from './components/Pointkeeper.jsx';
import Profiles from './components/PlayerProfiles.jsx';
import Room from './components/Room.jsx';
import Chat from './components/Gkchat.jsx';
import Lobby from './components/lobby.jsx';
import Authentication from './components/Authentication.jsx';
import UpdateButton from './components/update.jsx';




function App() {
  authChange(auth, (user) => {
    if (user) {
      console.log('User is signed in');
      $('.profile').text(user.email.charAt(0));
    } else {
      console.log('User is signed out');
    }
  });
  const goHome = () => {
    window.location.href = '/';
  }

  let component
  switch(window.location.pathname) {
    case '/':
      component = <Homepage/>
      break;
    case '/leaderboards':
      component = <SeasonSixAB />
      break;
    case '/rankings':
      component = <Rankings />
      break;
    case '/seasonsix':
      component = <SeasonSixAB />
      break;
    case '/season6':
      component = <Season6 />
      break;
    case '/season5':
      component = <Season5 />
      break;
    case '/season4':
      component = <Season4 />
      break;
    case '/seasonfourfive':
      component = <SeasonFourFive />
      break;
    case '/season3':
      component = <Season3 />
      break;
    case '/season2':
      component = <Season2 />
      break;
    case '/season1':
      component = <Season1 />
      break;
    case '/seasononetwothree':
      component = <SeasonOneTwoThree />
      break;
    case '/allTimeRankings':
      component = <AllTime />
      break;
    case '/characters':
      component = <Characters />
      break;
    case '/submissions':
      component = <Submissions />
      break;
    case '/casual':
      component = <Casual />
      break;
    case '/playerProfiles':
      component = <Profiles />
      break;
    case '/play':
      component = <Play />
      break;
    case '/pointkeeper':
      component = <Pointkeeper />
      break;
    case '/Room':
      component = <Room />
      break;
    
    case '/Chat':
      component = <Chat />
      break;

    case '/Lobby':
      component = <Lobby />
      break;
      
    case '/Authentication':
      component = <Authentication/>
      break;  
      
    default:
}
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [currentLogo, setCurrentLogo] = useState(logo);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    console.log('Window resized');
    setCurrentLogo(windowWidth > 1000 ? logo : logo2);
  }, [windowWidth]);


  const Swap = () => {

    if ($('.genie').attr('src') === genie) {
      $('.genie').attr('src', genie2);
    }else {
      $('.genie').attr('src', genie);
    }
}

  return (
    <div className="App">
      
      <div>
        
        <header>
          <h1 className="title" onClick={goHome}>Gatekeeper</h1>
          <img className="logo" onClick={goHome} src={currentLogo} alt="logo" />
          <img className="genie" onMouseEnter={Swap} onMouseLeave={Swap} src={genie} alt="genie" />
          <div className='profile' onClick={()=>{
            window.location.href = '/Authentication';
          }}>GK</div>
        </header>

        <Navbar/>
        {component}

        <div className="footer">
          <footer>
              <a href="https://www.youtube.com/@gatekeeper5258">Subscribe to our Youtube!</a>
              <h3>
                  © Gatekeeper 2023
              </h3>
              <UpdateButton />
          </footer>
        </div>


      </div>

    </div>
  );
}

export default App;
