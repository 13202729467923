import React, { useState, useContext } from 'react';
import { Context } from './Leaderboards.jsx';    
import './css/window.css';


const Window = (props) => {

    

    const redirect = () => {
        window.location.href = props.redirect;
        switch (props.rWindow){
        case 'Characters':
            window.location.href = '/characters';
            break;
        case 'Season6':
            window.location.href = '/season6';
            break;
        case 'Season5':
            window.location.href = '/season5';
            break;
        case 'Season4':
            window.location.href = '/season4';
            break;
        case 'Season3':
            window.location.href = '/season3';
            break;
        case 'Season2':
            window.location.href = '/season2';
            break;
        case 'Season1':
            window.location.href = '/season1';
            break;
        case 'AllTimeRankings':
            window.location.href = '/allTimeRankings';
            break;
        case 'Submissions':
            window.location.href = '/submissions';
            break;
        case 'Casual':
            window.location.href = '/casual';
            break;
        case 'Pointkeeper':
            window.location.href = '/pointkeeper';
            break;
            
    }  

    }

    const type = props.type;



    if (type === "frame") {
        return (
            <div className="window" style={{backgroundColor: props.color}} onClick={redirect}>
                <iframe src={props.frame}></iframe>
            </div>
        );
    }else if (type === "link") {
        
        return (
            <div className="window" onClick={redirect} style={{backgroundColor: props.color}} >
                <img src={props.image} alt="Logo" className='content'></img>
            </div>
        );
    }else {
        return (
            <div className="window" style={{backgroundColor: props.color}} onClick={redirect}>
                <p>There's nothing to see here</p>
            </div>
        );
};
};

export default Window;
