// Import the functions you need from the SDKs you need
import { initializeApp, getApps } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, signInWithPopup, GoogleAuthProvider} from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyARTlLsa4XFHK0VUKnglLWJtbaJspK09k4",
  authDomain: "gatekeeperonline-d74e7.firebaseapp.com",
  projectId: "gatekeeperonline-d74e7",
  storageBucket: "gatekeeperonline-d74e7.appspot.com",
  messagingSenderId: "344283400579",
  appId: "1:344283400579:web:58384e339887f969b20eb6",
  measurementId: "G-9SKKG1LB0B"
};

// Initialize Firebase
let app;
if (!getApps().length) {
  app = initializeApp(firebaseConfig);
} else {
  app = getApps()[0];
}

const db = getFirestore(app);

export { db };
export const auth = getAuth();
export const createUser = createUserWithEmailAndPassword;
export const signIn = signInWithEmailAndPassword;
export const authChange = onAuthStateChanged;
export const googleProvider = new GoogleAuthProvider(); 
export const popup = signInWithPopup;