import React, { useState } from 'react';
import StatNav from './statTrackerNav.jsx';
import Seasonsix from './seasonsix.jsx';

const SeasonSixAB = () => {
  const [selectedSeason, setSelectedSeason] = useState('submissions');

  const handleSeasonChange = (event) => {
    setSelectedSeason(event.target.value);
  };

  return (
    <div className='rankingBox'>
      <StatNav />
      <select value={selectedSeason} onChange={handleSeasonChange}>
        <option value="seasonsix">Season 6A</option>
        <option value="submissions">Season 6B</option>
      </select>
      {selectedSeason === 'seasonsix' && <Seasonsix season="seasonsix" />}
      {selectedSeason === 'submissions' && <Seasonsix season="submissions" />}
    </div>
  );
};

export default SeasonSixAB;


