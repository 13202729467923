import React from "react";

const characters = [
    "",
    "Anubis",
    "Behemoth",
    "Bijen",
    "Blind Sage",
    "Chef Genie",
    "Demos",
    "Dova",
    "Dr. Wiggler",
    "Dynamo",
    "Eldarian",
    "Eldo",
    "Flipper",
    "Fungal Spore 34",
    "Genie",
    "Goopster",
    "Gringo Giant",
    "Harambees",
    "Inquisitor",
    "JETT",
    "Kang Admi",
    "Karasu",
    "King of Krendulla",
    "Koen The Bear",
    "Lord of Light",
    "Mogul",
    "Paladin",
    "Penguin",
    "Prodigy",
    "Raven",
    "Salem",
    "Skin Walker",
    "The Moreno",
    "Time Keeper",
    "Wolverine",
    "Other"
  ];
  
export default characters;