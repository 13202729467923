import React, { useState, useEffect } from 'react';

const UpdateButton = () => {
  const [registration, setRegistration] = useState(null);
  const [updateAvailable, setUpdateAvailable] = useState(false);

  useEffect(() => {
    const handleUpdateAvailable = (event) => {
      setUpdateAvailable(true);
      setRegistration(event.currentTarget.active);
    };

    window.addEventListener('updateavailable', handleUpdateAvailable);

    return () => {
      window.removeEventListener('updateavailable', handleUpdateAvailable);
    };
  }, []);

  const handleUpdate = () => {
    if (registration) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      window.location.reload();
    }
  };

  return (
    <div>
      {updateAvailable && (
        <button onClick={handleUpdate}>Update Available</button>
      )}
    </div>
  );
};

export default UpdateButton;