import React, { useState, useContext, useEffect } from 'react';
import './css/rankings.css';


const Rankings = (props) => {
    
    const handleClickS6 = () => {
        window.location.href = '/season6';
        

    };
    const handleClickS5 = () => {
        window.location.href = '/season5';
        
    };
    const handleClickS4 = () => {
        window.location.href = '/season4';
        
    };
    const handleClickS3 = () => {
        window.location.href = '/season3';
                       
    };
    const handleClickS2 = () => {
        window.location.href = '/season2';
                 
    };
    const handleClickS1 = () => {
        window.location.href = '/season1';
        
    };
    const handleClickAT = () => {
        window.location.href = '/allTimeRankings';
        
    };
    const handleClickSUB = () => {
        window.location.href = '/submissions';
        
    };
    const handleClickCH = () => {
        window.location.href = '/characters';
        
    };
    const handleClickCA = () => {
        window.location.href = '/casual';
        
    }


    return (
        <div className="rankingBox">
            <div>
                <div className="rankingNav">
                    <div onClick={handleClickSUB}>Submissions</div>
                    <div onClick={handleClickS6}>Season 6</div>
                    <div onClick={handleClickS5}>Season 5</div>
                    <div onClick={handleClickS4}>Season 4</div>
                    <div onClick={handleClickS3}>Season 3</div>
                    <div onClick={handleClickS2}>Season 2</div>
                    <div onClick={handleClickS1}>Season 1</div>
                    <div onClick={handleClickAT}>All Time Rankings</div>
                    <div onClick={handleClickCH}>Characters</div>
                    <div onClick={handleClickCA}>Casual</div>
                </div>
            </div>
        </div>
    );
};

export default Rankings;
