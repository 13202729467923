import React, {useState, useEffect} from 'react';
import { db } from '../firebase';
import { collection, doc, setDoc, addDoc, getDoc, getDocs, query } from "firebase/firestore";
import $ from 'jquery';

import './css/pointkeeper.css';
import leftarrow from '../img/leftarrow.png';
import rightarrow from '../img/rightarrow.png';
import characters from './CharacterList';
import StatNav from './statTrackerNav';
import GatekeeperChess from './GatekeeperChess';
import Chat from './Gkchat';

    
const playerList = await getDocs(collection(db, "stattracker", 'leaderboards', 'player list'));

const Pointkeeper = () => {
    //#ea4848 #508fee #292929 #6cc431 #a27ef5 #ff8800
    function submit() {
        alert('You are about to submit the stats, click close to continue.');
        if (window.confirm('Press OK to submit or Cancel.')) {

        alert('Stats submitted!');
        const name = $('.toggle select').val();
        const firstName = $('.firstName').val();
        const lastName = $('.lastName').val();
        const date = new Date();
        console.log('submitting')
        
        const character = $('.cat:nth-child(3) select').val();
        function fullName() {
            if (firstName !== '' && lastName !== '') {
                const fullName = `${firstName} ${lastName}`;
                addDoc(collection(db, "stattracker", "leaderboards", "player list"),{
                    first: firstName,
                    last: lastName,
                });
                return fullName;
            } else if ((firstName === '' && lastName === '') && name === '') {
                // No name provided
                return '';
            } else {
                return name;
            }
        }
        const ogc = count[0]+count[1]+count[2]+count[3];
        const kills = count[7]+count[8];
        const numbers = [points,1,count[6],ogc,count[0],count[1],count[2],count[3],count[4],count[5],kills,count[7],count[8],count[9],count[10],count[11]];

        const stats = {
            name: fullName(),
            character: character,
            count: numbers,
            date: date,
        };

        
        addDoc(collection(db, "stattracker", "leaderboards", "submissions"), stats);
        setCount([0,0,0,0,0,0,0,0,0,0,0,0]);
        
        
}}

    const names = ['',];     
    playerList.forEach((doc) => {
        const firstName = doc.data().first;
        const lastName = doc.data().last;
        const fullName = `${firstName} ${lastName}`;
        names.push(fullName);
    });

    function newPlayer() {
        $('.toggle').toggleClass('noDisplay');
        
    }
    
    const [count, setCount] = useState([0,0,0,0,0,0,0,0,0,0,0,0]);
    const [points, setPoints] = useState(count[0]+count[1]+count[2]+count[3]+count[4]+count[5]+count[6]+count[7]+count[8]+count[11]);
    const [ogc, setOgc] = useState(count[0]+count[1]+count[2]+count[3]+count[4]);
    const [kills, setKills] = useState(count[7]+count[8]);
    useEffect(() => {
        setKills(count[7]+count[8]);
        setOgc(count[0]+count[1]+count[2]+count[3]);
        setPoints(count[0]+count[1]+count[2]+count[3]+count[4]+count[5]+count[6]+count[7]+count[8]+count[11]);
    },[count]);

   
        const decreaseCount = (num) => {
            setCount(prevCount => {
                const updatedCount = [...prevCount];
                updatedCount[num] = updatedCount[num] - 1;
                return updatedCount;
            });
        };
        
    function resetCount() {
        alert('Are you sure you want to reset?');
        if (window.confirm('Press OK to reset or Cancel to keep the counts.')) {
            setCount([0,0,0,0,0,0,0,0,0,0,0,0]);
        }
    }
    

    const increaseCount = (num) => {
        setCount(prevCount => {
            const updatedCount = [...prevCount];
            updatedCount[num] = updatedCount[num] + 1;
            return updatedCount;
        });
        
    };
    const Hide = () => {
        $('.cat').toggleClass('noDisplay');
        $('.dog').toggleClass('hidden');
        $('.submitPoints').toggleClass('noDisplay');
        const chatbox = document.querySelector('.gkchatbox');
        setTimeout(() => {
            
            chatbox.scrollTop = chatbox.scrollHeight;
        }, 500);
    }

    return (
        <div className='trackerContainer'>
            <StatNav/>
            <div className='pointkeeper'>
                <div className='header'>
                        <h2>Total Points:{points}</h2>
                        <button onClick={resetCount}>Reset</button>
                        <button onClick={Hide
                        } >Chat</button>
                        
                </div>
                <div className='dog hidden'>
                    <Chat/>
                </div>
                <div className='cat '>
                <div className='row'>
                    <div className='name ' >NAME:</div>
                    <div className="dropdown">
                        <div className='toggle ' >
                        <select>
                            {names.sort().map((name, index) => (
                                <option key={index} value={name}>{name}</option>
                            ))}
                        </select> 
                        </div>
                    </div> 
                    <div className='checkbox'>
                        <button className='newPlayerButton' onClick={newPlayer} >New?</button>
                    </div>
                    
                </div>
                <div className='newPlayerInfo toggle noDisplay'>
                    <div className>
                        <input className='firstName' type='text' placeholder='First Name' />
                        <input className='lastName' type='text' placeholder='Last Name' />
                    </div>
                </div>
                    
                    
                </div>
                <div className='cat'>
                    <div className='row'>
                        <div className='name ' >Character:</div>
                        <div className="dropdown">
                            <select>
                                {characters.map((character, index) => (
                                    <option key={index} value={character}>{character}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className='cat gates'>
                        <h3>Outer Gate Captures:{ogc}</h3>
                    <div className='row'>
                            <div className='statTracker red' >
                                <div className='title'>
                                    RED
                                </div> 
                                <div className='clicker '  >
                                    <div onClick={()=>decreaseCount(0)} className='button'>
                                        <img src={leftarrow} alt="left arrow" />
                                    </div>
                                    <div className='count'>{count[0]}</div>
                                    <div onClick={()=>increaseCount(0)} className='button'>
                                        <img src={rightarrow} alt="right arrow" />
                                    </div>
                                </div>     
                            </div>
                    <div className='statTracker blue' >
                        <div className='title'>
                            Blue
                        </div> 
                            <div className='clicker '  >
                                <div onClick={()=>decreaseCount(1)} className='button'>
                                    <img src={leftarrow} alt="left arrow" />
                                </div>
                                <div className='count'>{count[1]}</div>
                                <div onClick={()=>increaseCount(1)} className='button'>
                                    <img src={rightarrow} alt="right arrow" />
                                </div>
                            </div>     
                        </div>
                    </div>
                            <div className='row'>
                    <div className='statTracker black' >
                        <div className='title'>
                            Black
                        </div> 
                            <div className='clicker '  >
                                <div onClick={()=>decreaseCount(2)} className='button'>
                                    <img src={leftarrow} alt="left arrow" />
                                </div>
                                <div className='count'>{count[2]}</div>
                                <div onClick={()=>increaseCount(2)} className='button'>
                                    <img src={rightarrow} alt="right arrow" />
                                </div>
                            </div>     
                    </div>
                                <div className='statTracker green' >
                    <div className='title'>
                        Green
                    </div> 
                        <div className='clicker '   >
                            <div onClick={()=>decreaseCount(3)} className='button'>
                                <img src={leftarrow} alt="left arrow" />
                            </div>
                            <div className='count'>{count[3]}</div>
                            <div onClick={()=>increaseCount(3)} className='button'>
                                <img src={rightarrow} alt="right arrow" />
                            </div>
                        </div>     
                    </div>  
                    </div>
                        
                    <div className='row'>
                    <div className='statTracker purple' >
                    <div className='title'>
                        CGC
                    </div> 
                        <div className='clicker '  >
                            <div onClick={()=>decreaseCount(4)} className='button'>
                                <img src={leftarrow} alt="left arrow" />
                            </div>
                            <div className='count'>{count[4]}</div>
                            <div onClick={()=>increaseCount(4)} className='button'>
                                <img src={rightarrow} alt="right arrow" />
                            </div>
                        </div>     
                    </div>
                    <div className='statTracker blue' >
                    <div className='title'>
                        RS
                    </div> 
                        <div className='clicker '  >
                            <div onClick={()=>decreaseCount(5)} className='button'>
                                <img src={leftarrow} alt="left arrow" />
                            </div>
                            <div className='count'>{count[5]}</div>
                            <div onClick={()=>increaseCount(5)} className='button'>
                                <img src={rightarrow} alt="right arrow" />
                            </div>
                        </div>     
                    </div>
                                <div className='statTracker orange=' >
                    <div className='title'>
                        WIN
                    </div> 
                        <div className='clicker '  >
                            <div onClick={()=>decreaseCount(6)} className='button'>
                                <img src={leftarrow} alt="left arrow" />
                            </div>
                            <div className='count'>{count[6]}</div>
                            <div onClick={()=>increaseCount(6)} className='button'>
                                <img src={rightarrow} alt="right arrow" />
                            </div>
                        </div>     
                    </div>  
                    </div>
                </div>
                    
                
                <div className='cat'>
                <div className='row'>
                    <h3>Kills:{kills}</h3>
                </div>    
                    
                    <div className='row'>
                    <div className='statTracker black' >
                        <div className='title'>
                            Player Kills
                        </div> 
                        <div className='clicker '  >
                            <div onClick={()=>decreaseCount(7)} className='button'>
                                <img src={leftarrow} alt="left arrow" />
                            </div>
                            <div className='count'>{count[7]}</div>
                            <div onClick={()=>increaseCount(7)} className='button'>
                                <img src={rightarrow} alt="right arrow" />
                            </div>
                        </div>     
                    </div>             
                    <div className='statTracker purple' >
                        <div className='title'>
                        GK Kills
                        </div> 
                        <div className='clicker '  >
                            <div onClick={()=>decreaseCount(8)} className='button'>
                                <img src={leftarrow} alt="left arrow" />
                            </div>
                            <div className='count'>{count[8]}</div>
                            <div onClick={()=>increaseCount(8)} className='button'>
                                <img src={rightarrow} alt="right arrow" />
                            </div>
                        </div>     
                    </div> 
                    </div>
                    
                    </div>
                
                <div className='cat mis'>
                <h3>Miscellaneous</h3>
                    <div className='row'>
                    <div className='statTracker red' >
                    <div className='title'>
                        DOM
                    </div> 
                        <div className='clicker '  >
                            <div onClick={()=>decreaseCount(9)} className='button'>
                                <img src={leftarrow} alt="left arrow" />
                            </div>
                            <div className='count'>{count[9]}</div>
                            <div onClick={()=>increaseCount(9)} className='button'>
                                <img src={rightarrow} alt="right arrow" />
                            </div>
                        </div>     
                    </div>
                        <div className='statTracker green' >
                <div className='title'>
                    JP
                 </div> 
                <div className='clicker '  >
                    <div onClick={()=>decreaseCount(10)} className='button'>
                        <img src={leftarrow} alt="left arrow" />
                    </div>
                    <div className='count'>{count[10]}</div>
                    <div onClick={()=>increaseCount(10)} className='button'>
                        <img src={rightarrow} alt="right arrow" />
                    </div>
                </div>     
                </div>
                        <div className='statTracker orange' >
                <div className='title'>
                DR
                </div> 
                <div className='clicker '  >
                    <div onClick={()=>decreaseCount(11)} className='button'>
                        <img src={leftarrow} alt="left arrow" />
                    </div>
                    <div className='count'>{count[11]}</div>
                    <div onClick={()=>increaseCount(11)} className='button'>
                        <img src={rightarrow} alt="right arrow" />
                    </div>
                </div>     
                </div>  
                          
                    </div>
                </div>
                <button className='submitPoints' onClick={submit}>SUBMIT</button>
            </div>        
        </div>
        
    );
};

export default Pointkeeper;
